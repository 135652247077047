import {
  AUTH_LOADING,
  GET_COMPANY_DETAILS,
  GET_LATEST_WORK_HISTORY,
  GET_PASSWORD,
  GET_SKILL_BY_NAME,
  GET_USER_DETAILS,
  GET_USER_NAME,
  RESET_PASS_USER_EMAIL,
  RESET_PASSWORD_PAYLOAD,
  USER_PROFILE,
  USER_STATE
} from "@/store/modules/auth/constants";
import {
  AuthState,
  Company,
  ForgotPasswordPayload,
  SkillFinderFunction,
  UserState
} from "@/store/modules/auth/interfaces";
import { User } from "@/interfaces/data_objects/user";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { clean_string } from "@/utils/global";

export default {
  [AUTH_LOADING]: (state: AuthState): boolean => state.loading,
  [GET_USER_DETAILS]: (state: AuthState): User | null => state.user,
  [GET_COMPANY_DETAILS]: (state: AuthState): Company | null => state.company,
  [USER_STATE]: (state: AuthState): UserState => state.user_state,
  [USER_PROFILE]: (state: AuthState): Candidates.CandidateDetails | null =>
    state.user_profile,
  [GET_PASSWORD]: (state: AuthState): Text | undefined => state.user?.password,
  [GET_LATEST_WORK_HISTORY]: (
    state: AuthState
  ): Candidates.WorkHistoryDetails | null => {
    if (state.user_profile?.profile) {
      const work_history = state.user_profile.profile.work_history_detailed;
      if (work_history && Object.values(work_history).length > 0) {
        return Object.values(work_history)[0];
      }
    }
    return null;
  },
  [RESET_PASSWORD_PAYLOAD]: (state: AuthState): ForgotPasswordPayload | null =>
    state.reset_password_payload,
  [RESET_PASS_USER_EMAIL]: (state: AuthState): string | null =>
    state.email_for_reset_password,
  /**
   * Getter to return skill after searching skill by name from user profile
   * @param state
   */
  [GET_SKILL_BY_NAME]: (state: AuthState): SkillFinderFunction => {
    const userProfile = state.user_profile;
    const skills = userProfile ? userProfile.skills : null;
    return (value: string): Candidates.Skill | null => {
      if (skills) {
        const exist = skills.find((val) => {
          return clean_string(val.ref_skill || "") === clean_string(value);
        });
        if (exist) return exist;
        else return null;
      }
      return null;
    };
  },
  /**
   * Getter to return username after concatenating first & last name
   */
  [GET_USER_NAME]: (state: AuthState): string => {
    if (state.user) {
      const last_name = state.user.last_name ? state.user.last_name : "";
      return state.user.first_name + " " + last_name;
    }
    return "";
  }
};
