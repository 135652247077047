// Import States
import {
  GLOBAL_LOADING,
  STATE_ERROR,
  STATE_LOADING
} from "@/store/modules/talent_manager/constants";
// Import Talent Manager State Interface
import { TalentManagerState } from "@/store/modules/talent_manager/interfaces";
// Mutations
export default {
  [GLOBAL_LOADING]: (state: TalentManagerState, loading: boolean): void => {
    state.global_loading = loading;
  },
  [STATE_ERROR]: (state: TalentManagerState, loading: boolean): void => {
    state.global_loading = loading;
  },
  [STATE_LOADING]: (state: TalentManagerState, loading: boolean): void => {
    state.global_loading = loading;
  }
};
