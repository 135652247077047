// Import States
import {
  GLOBAL_LOADING,
  STATE_ERROR,
  STATE_LOADING
} from "@/store/modules/talent_manager/constants";
// Import Talent Manager State Interface
import { TalentManagerState } from "@/store/modules/talent_manager/interfaces";
// Getters
export default {
  [GLOBAL_LOADING]: (state: TalentManagerState): boolean =>
    state.global_loading,
  [STATE_ERROR]: (state: TalentManagerState): boolean => state.state_error,
  [STATE_LOADING]: (state: TalentManagerState): boolean => state.state_loading
};
