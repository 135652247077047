import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const chatbot_routes: RouteConfig = {
  path: "chatbot",
  component: () => import("@/views/recruiter/chatbot/ChatBotIndex.vue"),
  meta: {
    user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
  },
  children: [
    {
      path: "",
      name: "knowledge-base",
      component: () => import("@/views/recruiter/chatbot/KnowledgeBase.vue"),
      meta: {
        name: "recruiter.knowledge-base.main-title",
        icon: require("@/assets/icons/nav_sidebar/active/knowledge-base.svg"),
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    }
  ]
};
