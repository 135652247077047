import { IAppAssessment } from "@/interfaces";

export interface MyNextMoveState {
  data_loading: boolean;
  data_error: string;
  state: MyNextMoveAssessmentStates;
  scores: MyNextMoveScores; // For storing user ans
  selected_assessment: IAppAssessment.Assessment | null;
  selected_assessment_questions: IAppAssessment.AssessmentQuestionnaires[]; // For interests scores
  scores_assessment_ans: MyNextMoveScores | null; // For storing DB ans for interests scores
  interests_questions: InterestsQuestions; // For interests questions
  selected_assessment_result: IAppAssessment.AssessmentResults | null;
  questions_pagination: number;
  question_index: number;
  interests_questions_completed: boolean;
  selected_job_zone: number;
  selected_interest_area: InterestAreas;
  selected_job_zone_details: JobZoneDetails;
}

export interface JobZoneDetailsData {
  title: JobZoneDetails;
  experience: string;
  education: string;
  training: string;
  examples: string;
}

export enum MyNextMoveAssessmentStates {
  START = "START",
  START_DESCRIPTION = "START_DESCRIPTION",
  INTERESTS_SCORES = "INTERESTS_SCORES",
  INTERESTS_QUESTIONS = "INTERESTS_QUESTIONS",
  RESULTS = "RESULTS",
  JOB_ZONES = "JOB_ZONES",
  CAREERS = "CAREERS",
  MORE_CAREERS = "MORE_CAREERS"
}

export interface MyNextMoveScores {
  realistic: string;
  investigative: string;
  artistic: string;
  social: string;
  enterprising: string;
  conventional: string;
}

export interface AssessmentQuestionnairesWithAns
  extends IAppAssessment.AssessmentQuestionnaires {
  answer: string | null | number | JSON;
  answer_type: IAppAssessment.AnswerType;
  answer_id: number | null;
}
export interface InterestsQuestions {
  total: number;
  results: AssessmentQuestionnairesWithAns[];
}

export enum InterestAreas {
  REALISTIC = "Realistic",
  INVESTIGATIVE = "Investigative",
  ARTISTIC = "Artistic",
  SOCIAL = "Social",
  ENTERPRISING = "Enterprising",
  CONVENTIONAL = "Conventional"
}

export interface OnetResultResponseData {
  job_title: string;
  jid: number;
}

export enum JobZoneDetails {
  LITTLE_NO_PREPARATION = "Little or No Job Preparation Needed",
  SOME_JOB_PREPERATION = "Some Job Preparation Needed",
  MEDIUM_JOB_PREPERATION = "Medium Job Preparation Needed",
  HIGH_JOB_PREPERATION = "High Job Preparation Needed",
  EXTENSIVE_JOB_PREPERATION = "Extensive Job Preparation Needed"
}

export const JobZoneData: JobZoneDetailsData[] = [
  {
    title: JobZoneDetails.LITTLE_NO_PREPARATION,
    experience:
      "Little or no previous work-related skill, knowledge, or experience is needed for these careers. For example, a person can become a waiter or waitress even if he/she has never worked before.",
    education:
      "Some of these careers may need a high school diploma or GED certificate.",
    examples:
      "These careers involve following instructions and helping others. Examples include food prepration workers, dishwashers, floor sanders and finishers, landscaping and groundskeeping workers, logging equipment operators, and baristas.",
    training:
      "Employees in these careers need from a few days to a few months of training. Usually, an experienced worker could show you how to do the job."
  },
  {
    title: JobZoneDetails.SOME_JOB_PREPERATION,
    experience:
      "Some previous work-related skill, knowledge, or experience is usually needed. For example, it would help a teller to have experience working directly with the public.",
    education: "These careers often require a high school diploma.",
    examples:
      "These careers often involve using your knowledge and skills to help others. Examples include orderlies, counter and rental clerks, customer service representatives, secruity guards, upholsterers, tellers, dental laboratory technicians.",
    training:
      "Employees in these careers need anywhere from a few months to one year of working with experienced employees. A apprenticeship program may be available for these careers."
  },
  {
    title: JobZoneDetails.MEDIUM_JOB_PREPERATION,
    experience:
      "Previous work-related skill, knowledge, or experience is needed for these careers. For example, an electrician must  be in an apprenticeship for three or four years or several years of job training. You may need to pass a test to get a license to do the job.",
    education:
      "Most of these careers need vocational school training, on-the-job experience, or an associate's degree.",
    examples:
      "These careers usually involve using communication and organizational skills to coordinate, supervise, manage, or train others to accomplish goals. Examples include hydroeclectric production managers, desktop publishers, electicians, agricultural technicians, barbers, court reporters and simultaneous captioners, and medical assistants.",
    training:
      "Employees in these careers need one or two years of training. Both on-the-job experience and informal training with experienced workers may be needed. An recognized apprenticeship program may be a good choice for these careers."
  },
  {
    title: JobZoneDetails.HIGH_JOB_PREPERATION,
    experience:
      "Long Term work-related skill, knowledge, or experience is needed for these careers. For example and accountant must complete four years of college and work several years in the field of qualified for the job.",
    education:
      "Most of these careers require a four-year bachelor's degree, but some do not.",
    examples:
      "Mant of these careers involve coordinating, supervising, managing, or training others. Examples include real estate brokers, sales managers, database administrators, graphic designers, conversation scientists, art directors, and cost estimators.",
    training:
      "Employees in these careers need sevaral years of work-related experience and training. Both on-the-job and classroom job training may be needed."
  },
  {
    title: JobZoneDetails.EXTENSIVE_JOB_PREPERATION,
    experience:
      "Extensive skill, knowledge, and experience are needed for these careers. Many require more than five years of experience. For example, a surgeon must complete four year of college and additional five to seven year of specialized medical training to be able to do thier job.",
    education:
      "Most of these careers need a graduate school education. For example, they may require a master's degree, and some require a Ph.D., M.D., or J.D. (law degree).",
    examples:
      "These careers often involve coordinating, training, supervising, or managing the activities of others to accomplish goals. Very advanced communication and organizational skills are required. Examples include pharmacists, lawyers, astronomers, biologists, clergy, physical assistants, and veterinarians.",
    training:
      "Employees may need some on-the-job training. However, the person will usually have the needed skills, knowledge, work-related experience, and training before starting the job."
  }
];
