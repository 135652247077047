import {
  candidate_base_url,
  nep_base_url,
  recruiter_base_url
} from "@/store/urls/base_urls";

export const RECRUITER_ONBOARDING = recruiter_base_url + "onboarding/";
export const CANDIDATE_ONBOARDING = candidate_base_url + "onboarding/";

export const NEP_POST_DATA = nep_base_url + "v1/user/post-data";
