import {
  auth_base_url,
  candidate_base_url,
  nep_base_url,
  social_auth_base_url,
  user_base_url
} from "@/store/urls/base_urls";

export const LOGIN = nep_base_url + "v1/user/login";
export const SIGNUP = nep_base_url + "v1/user/signup";
export const VERIFY_EMAIL = auth_base_url + "verify-email/";
export const GET_USER = user_base_url;

export const GOOGLE_AUTH = social_auth_base_url + "auth/google/";
export const LINKEDIN_AUTH = social_auth_base_url + "auth/linkedin/";

export const USER_PROFILE = user_base_url + "profile/";

export const UPDATE_USER_DETAILS = user_base_url;

// For reset password
export const RESET_PASSWORD = auth_base_url + "reset-password/";
// For fetching email token for resetting password
export const FORGOT_PASSWORD = auth_base_url + "forgot-password/";
export const UPDATE_PROFILE = user_base_url + "edit-details/";

export const UPDATE_COMPANY_DETAILS = user_base_url + "update-company/";
// For uploading highlight awards
export const UPLOAD_HIGHLIGHT_AWARDS = candidate_base_url + "awards/";
export const REMOVE_HIGHLIGHT_AWARDS = candidate_base_url + "award/";

export const REFRESH_TOKEN = auth_base_url + "refresh-token/";
export const LOGOUT_USER = auth_base_url + "logout-user/";
