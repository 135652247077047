import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const recruiter_jobs: RouteConfig = {
  path: "jobs",
  component: () => import("@/views/recruiter/RecruiterJobs.vue"),
  meta: {
    name: "nav-sidebar.jobs",
    icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
    user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
  },
  children: [
    {
      path: "",
      name: "recruiter-job-listing",
      component: () => import("@/views/recruiter/jobs/JobListing.vue"),
      meta: {
        name: "nav-sidebar.jobs",
        icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    },
    {
      path: "post-job",
      component: () =>
        import("@/views/recruiter/jobs/post_new_job/PostNewJobWrapper.vue"),
      meta: {
        name: "nav-sidebar.jobs",
        icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      },
      children: [
        {
          path: "",
          name: "recruiter-post-job",
          component: () => import("@/views/recruiter/jobs/PostNewJob.vue"),
          meta: {
            name: "nav-sidebar.jobs",
            icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
            user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
          }
        },
        {
          path: "search/:job_title",
          name: "recruiter-search-standardised-profile",
          component: () =>
            import("@/views/recruiter/jobs/ViewStandardisedJobProfiles.vue"),
          meta: {
            name: "nav-sidebar.jobs",
            icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
            user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
          }
        },
        {
          path: ":id/view",
          name: "view-standardised-job",
          component: () =>
            import(
              "@/views/recruiter/jobs/post_new_job/ViewStandardisedJob.vue"
            ),
          meta: {
            name: "nav-sidebar.jobs",
            icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
            user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
          }
        },
        {
          path: ":id/edit",
          name: "edit-standardised-job",
          component: () =>
            import(
              "@/views/recruiter/jobs/post_new_job/EditStandardisedJob.vue"
            ),
          meta: {
            name: "nav-sidebar.jobs",
            icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
            user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
          }
        }
      ]
    },
    // {
    //   path: "search/:job_title",
    //   name: "recruiter-search-standardised-profile",
    //   component: () =>
    //     import("@/views/recruiter/jobs/ViewStandardisedJobProfiles.vue"),
    //   meta: {
    //     user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
    //   }
    // },
    {
      path: ":id",
      name: "recruiter-view-job",
      component: () =>
        import("@/views/recruiter/jobs/ViewJobWithCandidates.vue"),
      meta: {
        name: "nav-sidebar.jobs",
        icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    },
    {
      path: ":id/edit",
      name: "recruiter-edit-job",
      component: () => import("@/views/recruiter/jobs/EditJob.vue"),
      meta: {
        name: "nav-sidebar.jobs",
        icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    }
  ]
};
