import {
  NEP_POST_DATA,
  ONBOARD_CANDIDATE,
  ONBOARD_RECRUITER,
  SET_ONBOARDING_LOADING
} from "@/store/modules/onboarding/constants";
import { Commit } from "vuex";
import {
  candidate_onboarding,
  nep_post_data,
  recruiter_onboarding
} from "@/store/apis/onboarding";
import { SET_COMPANY, SET_USER } from "@/store/modules/auth/constants";
import { Company } from "@/store/modules/auth/interfaces";
import { User } from "@/interfaces/data_objects/user";
import { ROOT_ERROR } from "@/store/modules/root/constants";

export default {
  [ONBOARD_RECRUITER]: async (
    { commit }: { commit: Commit },
    payload: FormData
  ): Promise<boolean> => {
    try {
      commit(SET_ONBOARDING_LOADING, true);
      const response = await recruiter_onboarding(payload);
      const user: User = response.data.user;
      const company: Company = response.data.company;
      // Update user in store
      commit(`auth/${SET_USER}`, user, { root: true });
      commit(`auth/${SET_COMPANY}`, company, { root: true });
      commit(SET_ONBOARDING_LOADING, false);
      return true;
    } catch (e) {
      commit(SET_ONBOARDING_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
      return false;
    }
  },
  [ONBOARD_CANDIDATE]: async (
    { commit }: { commit: Commit },
    payload: FormData
  ): Promise<boolean> => {
    try {
      commit(SET_ONBOARDING_LOADING, true);
      const response = await candidate_onboarding(payload);
      const user: User = response.data;
      // Update user in store
      commit(`auth/${SET_USER}`, user, { root: true });
      commit(SET_ONBOARDING_LOADING, false);
      return true;
    } catch (e) {
      commit(SET_ONBOARDING_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });

      return false;
    }
  },
  [NEP_POST_DATA]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: any
  ): Promise<any | null> => {
    try {
      const resp = await nep_post_data(payload);
      return resp.data.data;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  }
};
