import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const talent_manager_jobs: RouteConfig = {
  path: "jobs",
  component: () => import("@/views/recruiter/RecruiterJobs.vue"),
  meta: {
    user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
  },
  children: [
    {
      path: "",
      name: "talent-manager-job-listing",
      component: () => import("@/views/recruiter/jobs/JobListing.vue"),
      meta: {
        name: "nav-sidebar.jobs",
        icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
        user_state: [UserState.TALENT_MANAGER]
      }
    },
    {
      path: ":id",
      name: "talent-manager-view-job",
      component: () =>
        import("@/views/recruiter/jobs/ViewJobWithCandidates.vue"),
      meta: {
        name: "nav-sidebar.jobs",
        icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
        user_state: [UserState.TALENT_MANAGER]
      }
    }
  ]
};
