import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const recruiter_interview_routes: RouteConfig = {
  path: "interviews",
  component: () => import("@/views/recruiter/interview/InterviewIndex.vue"),
  meta: {
    user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
  },
  children: [
    {
      path: "",
      name: "recruiter-interviews",
      component: () =>
        import("@/views/recruiter/interview/InterviewListing.vue"),
      meta: {
        name: "nav-sidebar.interviews",
        icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    }
  ]
};
