/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GET_JOB_BY_ID,
  GET_JOB_BY_KEY,
  GET_UNIQUE_SECTORS,
  ROLES,
  COMMON_LOADING,
  SET_UNIQUE_SECTORS,
  FETCH_SKILLS_GROUP,
  JOB_SEARCH,
  INIT_COMMON_DATA,
  SKILL_SEARCH,
  GET_USER_WITH_PROFILE,
  INIT_NOTIFICATIONS,
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_COUNT,
  NOTIFICATIONS,
  NOTIFICATION_ACTION,
  NOTIFICATION_ACTION_LOADING,
  GET_ASSESSMENT_SUMMARY,
  GET_ASSESSMENT_PDF,
  GET_ASSESSMENT,
  GET_USER_INFO,
  USER_INFO,
  APP_ASSESSMENTS,
  APP_ASSESSMENT_RESULT,
  APP_ASSESSMENT_RESULT_PDF,
  UPLOAD_FILE_CHUNK,
  UPLOAD_FILES
} from "@/store/modules/common/constants";
import { Commit, Dispatch } from "vuex";
import {
  fetch_assessment,
  fetch_assessment_pdf,
  fetch_assessment_summary,
  fetch_skills,
  fetch_skills_group,
  fetch_user_with_profile,
  get_job_by_id,
  get_job_by_key,
  get_notifications_count,
  get_notifications_list,
  get_roles_list,
  get_unique_sectors,
  update_notifications_status,
  fetch_user_info,
  app_assessments,
  app_assessments_result,
  app_assessments_result_pdf,
  upload_file_chunk,
  upload_files
} from "@/store/apis/common";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { Jobs } from "@/interfaces/data_objects/jobs";
import {
  GetJobsPostedPayload,
  InterviewListingCandidates
} from "@/store/modules/recruiter/interfaces";
import { get_jobs_posted_by_recruiter } from "@/store/apis/recruiter";
import router from "@/router";
import {
  AppAssessmentResultPayload,
  SkillSearchPayload,
  UpdateNotificationStatusPayload
} from "@/store/modules/common/interfaces";
import { Skills } from "@/interfaces/data_objects/skills";
import { parseStrToInt, wait_until } from "@/utils/global";
import { USER_STATE } from "@/store/modules/auth/constants";
import { UserState } from "@/store/modules/auth/interfaces";
import { ASSESSMENT_ERROR } from "@/store/modules/candidates/constants";
import { AssessmentSummary } from "@/interfaces/assessments";
import { PrincipleAssessments } from "@/interfaces/data_objects/principle_assessments";

export default {
  /**
   * Get unique sectors/industries
   */
  [GET_UNIQUE_SECTORS]: async ({
    commit
  }: {
    commit: Commit;
  }): Promise<void> => {
    try {
      commit(COMMON_LOADING, true);
      const response = await get_unique_sectors();
      commit(SET_UNIQUE_SECTORS, response.data);
      commit(COMMON_LOADING, false);
    } catch (e) {
      //
    }
  },
  [ROLES]: async ({ commit }: { commit: Commit }): Promise<void> => {
    try {
      const response = await get_roles_list();
      commit(ROLES, response.data.data);
    } catch (e) {
      await router.push("/error");
    }
  },
  /**
   * Function to get job by jid(primary key)
   */
  [GET_JOB_BY_ID]: async (
    { commit }: { commit: Commit },
    job_id: number
  ): Promise<Jobs | null> => {
    try {
      commit(COMMON_LOADING, true);
      // await new Promise((resolve) => setTimeout(resolve, 4000));
      const response = await get_job_by_id(job_id);
      commit(GET_JOB_BY_ID, response.data.data);
      commit(COMMON_LOADING, false);
      return response.data.data;
    } catch (e) {
      commit(COMMON_LOADING, false);
      commit(GET_JOB_BY_ID, null);
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  /**
   * Vuex action to retrieve job details by a job key and update the store.
   *
   * This asynchronous action commits to the Vuex store the state of loading
   * before and after an API call to retrieve job details. It uses the job key
   * to fetch the job's data and then commits the result to the store. If an
   * error occurs during the API call, it handles the error by committing a null
   * value for the job data and an error state to the store's root.
   *
   * @param {Commit} commit - The Vuex `commit` method to update the store's state.
   * @param {string} job_key - The unique key identifier for the job to be fetched.
   * @returns {Promise<Jobs | null>} - A promise that resolves to the job data or null if an error occurs.
   *
   * Note: The action uses `COMMON_LOADING` to toggle the loading state in the store,
   * `GET_JOB_BY_KEY` to set the fetched job data, and `ROOT_ERROR` to report errors.
   * The actual fetching of data is delegated to the `get_job_by_key` function.
   * It is assumed that `COMMON_LOADING`, `GET_JOB_BY_KEY`, and `ROOT_ERROR` are
   * predefined mutations in the Vuex store, and `Jobs` is a type that represents
   * the structure of the job data expected to be received.
   */
  [GET_JOB_BY_KEY]: async (
    { commit }: { commit: Commit },
    job_key: string
  ): Promise<Jobs | null> => {
    try {
      commit(COMMON_LOADING, true);
      // await new Promise((resolve) => setTimeout(resolve, 4000));
      const response = await get_job_by_key(job_key);
      commit(GET_JOB_BY_KEY, response.data.data);
      commit(COMMON_LOADING, false);
      return response.data.data;
    } catch (e) {
      commit(COMMON_LOADING, false);
      commit(GET_JOB_BY_KEY, null);
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  /**
   * Fetch skills group => payload: skills: string[]
   */
  [FETCH_SKILLS_GROUP]: async (
    { commit }: { commit: Commit },
    payload: string[]
  ): Promise<any> => {
    try {
      commit(COMMON_LOADING, true);
      const obj = {
        skills: payload
      };
      const form_data = new FormData();
      form_data.append("skills", payload as never);
      const response = await fetch_skills_group(obj);
      commit(COMMON_LOADING, false);
      return response.data.data.skill_group;
    } catch (e) {
      commit(COMMON_LOADING, false);
      return null;
    }
  },
  [JOB_SEARCH]: async (
    { commit }: { commit: Commit },
    payload: GetJobsPostedPayload
  ): Promise<Jobs[]> => {
    try {
      commit(COMMON_LOADING, true);
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      const response = await get_jobs_posted_by_recruiter(payload);
      commit(COMMON_LOADING, false);
      return response.data.data.results;
    } catch (e) {
      commit(COMMON_LOADING, false);
      commit(ROOT_ERROR, null, { root: true });
      return [];
    }
  },
  /**
   * Action to initialize startup data
   */
  [INIT_COMMON_DATA]: async ({
    dispatch
  }: {
    dispatch: Dispatch;
  }): Promise<boolean> => {
    try {
      await dispatch(ROLES);
      await dispatch(GET_UNIQUE_SECTORS);
      await dispatch(APP_ASSESSMENTS);
      return true;
    } catch (e) {
      await router.push("/error");
      return false;
    }
  },
  /**
   * Action to search skills
   */
  [SKILL_SEARCH]: async (
    { commit }: { commit: Commit },
    payload: SkillSearchPayload
  ): Promise<Skills[]> => {
    try {
      commit(COMMON_LOADING, true);
      const response = (await fetch_skills(payload)).data.data.results;
      commit(COMMON_LOADING, false);
      return response;
    } catch (e) {
      return [];
    }
  },
  [GET_USER_WITH_PROFILE]: async (
    { commit }: { commit: Commit },
    uid: number
  ): Promise<InterviewListingCandidates | null> => {
    try {
      commit(COMMON_LOADING, true);
      const response = await fetch_user_with_profile(uid);
      commit(COMMON_LOADING, false);
      return response.data.data;
    } catch (e) {
      commit(COMMON_LOADING, false);
      return null;
    }
  },
  [INIT_NOTIFICATIONS]: async ({
    commit,
    rootGetters
  }: {
    commit: Commit;
    rootGetters: any;
  }): Promise<void> => {
    try {
      const loop = true;
      while (
        loop &&
        rootGetters[`auth/${USER_STATE}`] !== UserState.UNAUTHENTICATED
      ) {
        commit(NOTIFICATIONS_LOADING, true);
        // Get notifications count
        const notifications_count = await get_notifications_count();
        const count = parseStrToInt(notifications_count.data.data.count);
        commit(NOTIFICATIONS_COUNT, count ?? 0);
        if (count && count > 0) {
          const notifications_response = await get_notifications_list({
            page: 0,
            limit: 20
          });
          const notifications = notifications_response.data.data.results;
          commit(NOTIFICATIONS, notifications);
        }
        commit(NOTIFICATIONS_LOADING, false);
        await wait_until(45000);
      }

      commit(NOTIFICATIONS_LOADING, false);
    } catch (e) {
      commit(NOTIFICATIONS_LOADING, false);
    }
  },
  [NOTIFICATION_ACTION]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: UpdateNotificationStatusPayload
  ): Promise<void> => {
    try {
      commit(NOTIFICATION_ACTION_LOADING, true);
      await update_notifications_status(payload);
      // Get notifications count
      const notifications_count = await get_notifications_count();
      const count = parseStrToInt(notifications_count.data.data.count);
      commit(NOTIFICATIONS_COUNT, count ?? 0);
      // Reset notifications
      const notifications_response = await get_notifications_list({
        page: 0,
        limit: 20
      });
      const notifications = notifications_response.data.data.results;
      commit(NOTIFICATIONS, notifications);

      commit(NOTIFICATION_ACTION_LOADING, false);
    } catch (e) {
      //
    }
  },
  [GET_ASSESSMENT_SUMMARY]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: any
  ): Promise<AssessmentSummary.Root | null> => {
    try {
      const response = await fetch_assessment_summary(payload);
      return response.data.data;
    } catch (e) {
      commit(`candidate/${ASSESSMENT_ERROR}`, true, { root: true });
      return null;
    }
  },
  [GET_ASSESSMENT_PDF]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: any
  ): Promise<AssessmentSummary.Root | null> => {
    try {
      const response = await fetch_assessment_pdf(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [GET_ASSESSMENT]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    user_id: number
  ): Promise<PrincipleAssessments | null> => {
    try {
      const response = await fetch_assessment(user_id);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },

  [GET_USER_INFO]: async (
    { commit }: { commit: Commit },
    name: string
  ): Promise<PrincipleAssessments | null> => {
    try {
      const response = await fetch_user_info(name);
      if (response.data.data !== null) {
        commit(USER_INFO, response.data.data);
      }
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [APP_ASSESSMENTS]: async ({
    commit
  }: {
    commit: Commit;
  }): Promise<boolean> => {
    try {
      const response = await app_assessments();
      commit(APP_ASSESSMENTS, response.data.data);
      return true;
    } catch (e) {
      return false;
    }
  },
  [APP_ASSESSMENT_RESULT]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: AppAssessmentResultPayload
  ): Promise<any> => {
    try {
      const response = await app_assessments_result(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [APP_ASSESSMENT_RESULT_PDF]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: AppAssessmentResultPayload
  ): Promise<any> => {
    try {
      const response = await app_assessments_result_pdf(payload);
      return response.data.data;
    } catch (e) {
      return null;
    }
  },
  [UPLOAD_FILE_CHUNK]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: FormData
  ): Promise<boolean> => {
    try {
      commit(UPLOAD_FILE_CHUNK, true);
      await upload_file_chunk(payload);
      commit(UPLOAD_FILE_CHUNK, false);
      return true;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      commit(UPLOAD_FILE_CHUNK, false);
      return false;
    }
  },
  [UPLOAD_FILES]: async (
    {
      commit
    }: {
      commit: Commit;
    },
    payload: any
  ): Promise<any> => {
    try {
      const response = await upload_files(payload);
      return response.data.data;
    } catch (e) {
      return false;
    }
  }
};
