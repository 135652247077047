import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";
import { candidate_interview_routes } from "@/router/sub_routes/candidates/candidate_interview_routes";
import { candidate_assessment_routes } from "@/router/sub_routes/candidates/candidate_assessment_routes";

export const candidates_routes: RouteConfig = {
  path: "/candidate",
  component: () => import("@/views/candidate/CandidateWrapper.vue"),
  meta: {
    user_state: [UserState.CANDIDATE]
  },
  children: [
    {
      path: "",
      redirect: {
        name: "candidate-dashboard"
      }
    },
    {
      path: "dashboard",
      name: "candidate-dashboard",
      component: () => import("@/views/candidate/CandidateDashboard.vue"),
      meta: {
        name: "nav-sidebar.dashboard",
        icon: require("@/assets/icons/nav_sidebar/active/dashboard.svg"),
        user_state: [UserState.CANDIDATE]
      }
    },
    {
      path: "nep-test",
      name: "nep-interview-test",
      component: () => import("@/views/nep/NepInterview.vue"),
      meta: {
        name: "NEP Interview Test",
        icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        user_state: [UserState.CANDIDATE]
      }
    },
    {
      path: "nep-report",
      name: "nep-report-detail",
      component: () => import("@/views/nep/NepReport.vue"),
      meta: {
        name: "NEP Interview Test",
        icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        user_state: [UserState.CANDIDATE]
      }
    },
    {
      path: "nep-result",
      name: "nep-report-detail",
      component: () => import("@/views/nep/NepAIResults.vue"),
      meta: {
        name: "NEP Interview Test",
        icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        user_state: [UserState.CANDIDATE]
      }
    },
    candidate_assessment_routes,
    {
      path: "error",
      name: "candidate-error",
      component: () => import("@/views/ErrorPage.vue"),
      meta: {
        name: "nav-sidebar.error",
        icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
        user_state: [UserState.CANDIDATE]
      }
    },
    {
      path: "*",
      component: () => import("../views/NotFound.vue"),
      meta: {
        name: "nav-sidebar.not-found",
        icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
        user_state: [
          UserState.UNAUTHENTICATED,
          UserState.EMAIL_VERIFICATION_PENDING,
          UserState.EMAIL_VERIFICATION_COMPLETED,
          UserState.ONBOARDING_PENDING,
          UserState.RECRUITER,
          UserState.RECRUITER_ADMIN,
          UserState.CANDIDATE
        ]
      }
    }
  ]
};
