import getters from "@/store/modules/talent_manager/getters";
import mutations from "@/store/modules/talent_manager/mutations";
import actions from "@/store/modules/talent_manager/actions";
import { TalentManagerState } from "@/store/modules/talent_manager/interfaces";
// Index Of Talent Manager Module
export default {
  namespaced: true,
  state: (): TalentManagerState => ({
    global_loading: false,
    state_error: false,
    state_loading: false
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
