export const GET_ONBOARDING_LOADING = "GET_ONBOARDING_LOADING";
export const SET_ONBOARDING_LOADING = "SET_ONBOARDING_LOADING";
export const GET_RECRUITER_DATA = "GET_RECRUITER_DATA";
export const RESET_RECRUITER_DATA = "RESET_RECRUITER_DATA";
export const GET_CANDIDATE_DATA = "GET_CANDIDATE_DATA";
export const RESET_CANDIDATE_DATA = "RESET_CANDIDATE_DATA";
export const SET_RECRUITER_DATA = "SET_RECRUITER_DATA";
export const SET_CANDIDATE_DATA = "SET_CANDIDATE_DATA";

export const ONBOARD_RECRUITER = "ONBOARD_RECRUITER";
export const ONBOARD_CANDIDATE = "ONBOARD_CANDIDATE";

export const NEP_POST_DATA = "NEP_POST_DATA";
