import { IAppAssessment } from "@/interfaces";
import {
  INTERESTS_QUESTIONS_COMPLETED,
  NEXT_MOVE_ASSESSMENT_SCORES,
  NEXT_MOVE_ASSESSMENT_STATE,
  NEXT_MOVE_DATA_ERROR,
  NEXT_MOVE_DATA_LOADING,
  NEXT_MOVE_INTERESTS_QUESTIONS,
  NEXT_MOVE_JOB_ZONE_DETAILS,
  NEXT_MOVE_QUESTIONS_PAGINATION,
  NEXT_MOVE_QUESTION_INDEX,
  NEXT_MOVE_SCORES_ASSESSMENT_ANS,
  NEXT_MOVE_SELECTED_ASSESSMENT,
  NEXT_MOVE_SELECTED_ASSESSMENT_QUESTIONS,
  NEXT_MOVE_SELECTED_ASSESSMENT_RESULT,
  NEXT_MOVE_SELECTED_INTEREST_AREA,
  NEXT_MOVE_SELECTED_JOB_ZONE
} from "./constants";
import {
  InterestAreas,
  InterestsQuestions,
  JobZoneDetails,
  MyNextMoveAssessmentStates,
  MyNextMoveScores,
  MyNextMoveState
} from "./interfaces";

export default {
  // Fetching the state from the state
  [NEXT_MOVE_ASSESSMENT_STATE]: (
    state: MyNextMoveState
  ): MyNextMoveAssessmentStates => state.state,
  // Fetching the scores from the state
  [NEXT_MOVE_ASSESSMENT_SCORES]: (state: MyNextMoveState): MyNextMoveScores =>
    state.scores,
  // Fetching selected assessment from store
  [NEXT_MOVE_SELECTED_ASSESSMENT]: (
    state: MyNextMoveState
  ): IAppAssessment.Assessment | null => state.selected_assessment,
  // Fetchin selected assessment questions from store
  [NEXT_MOVE_SELECTED_ASSESSMENT_QUESTIONS]: (
    state: MyNextMoveState
  ): IAppAssessment.AssessmentQuestionnaires[] =>
    state.selected_assessment_questions,
  // Fetch data loading from store
  [NEXT_MOVE_DATA_LOADING]: (state: MyNextMoveState): boolean =>
    state.data_loading,
  // Fetch data error from store
  [NEXT_MOVE_DATA_ERROR]: (state: MyNextMoveState): string => state.data_error,
  // Fetch scores assessment ans from store
  [NEXT_MOVE_SCORES_ASSESSMENT_ANS]: (
    state: MyNextMoveState
  ): MyNextMoveScores | null => state.scores_assessment_ans,
  // Fetch interests questions from store
  [NEXT_MOVE_INTERESTS_QUESTIONS]: (
    state: MyNextMoveState
  ): InterestsQuestions => state.interests_questions,
  // Fetch interests questions pagination from store
  [NEXT_MOVE_QUESTIONS_PAGINATION]: (state: MyNextMoveState): number =>
    state.questions_pagination,
  // Fetch question index from store
  [NEXT_MOVE_QUESTION_INDEX]: (state: MyNextMoveState): number =>
    state.question_index,
  [INTERESTS_QUESTIONS_COMPLETED]: (state: MyNextMoveState): boolean =>
    state.interests_questions_completed,
  [NEXT_MOVE_SELECTED_ASSESSMENT_RESULT]: (
    state: MyNextMoveState
  ): IAppAssessment.AssessmentResults | null =>
    state.selected_assessment_result,
  [NEXT_MOVE_SELECTED_JOB_ZONE]: (state: MyNextMoveState): number =>
    state.selected_job_zone,
  [NEXT_MOVE_SELECTED_INTEREST_AREA]: (state: MyNextMoveState): InterestAreas =>
    state.selected_interest_area,
  [NEXT_MOVE_JOB_ZONE_DETAILS]: (state: MyNextMoveState): JobZoneDetails =>
    state.selected_job_zone_details
};
