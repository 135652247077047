import { Candidates } from "@/interfaces/data_objects/candidates";
import CandidateDetails = Candidates.CandidateDetails;
import { SocialLinks } from "@/store/modules/onboarding/interfaces";

export enum ViewCandidatesOptions {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED"
}

export enum CandidateCardViewOptions {
  approved,
  declined
}

export interface AllCandidates {
  user_data: User;
  candidate_details: CandidateDetails;
  id: number;
}
interface User {
  id: number;
  resume_uri: string;
  availability: string[];
  avatar_uri: string;
  social_links?: SocialLinks[];
  first_name: string;
  last_name?: string;
}
